.profile-input-tel {
  width: 100%;
  margin-bottom: 28px;
  position: relative;
}
.profile-input-tel .MuiFormControl-root {
  margin: 0;
}
.profile-input-tel__label {
  display: block;
  color: var(--primary-text-color);
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 8.2px !important;
}
.profile-input-tel .MuiInputBase-input {
  padding: 12px 10px;
  font-size: 12px;
  line-height: 12px;
  color: var(--secondary-text-color);
}
.profile-input-tel .MuiInputBase-input:disabled {
  background: rgba(255, 255, 255, 0.1) !important;
}
.profile-input-tel .Typography-error {
  bottom: -12px;
}
.profile-input-tel .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
}
