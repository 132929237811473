.profile-deposit {
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color);
  margin-bottom: 24px;
  height: 100%;
  min-height: 450px;
}
.profile-deposit__container {
  padding: 14px 14px 24px;
  position: relative;
}
.profile-deposit__restrictions {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1b2740c7;
  z-index: 2;
  border-bottom-left-radius: var(--primary-border-radius);
  border-bottom-right-radius: var(--primary-border-radius);
}
.profile-deposit__title {
  color: var(--primary-text-color);
  margin-bottom: 18px !important;
}
.profile-deposit__grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)) !important;
  grid-template-rows: minmax(100px, 1fr) !important;
  column-gap: 19px !important;
  row-gap: 14px !important;
  justify-items: stretch !important;
}
