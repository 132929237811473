
.wallets-container {
   background-color: var(--secondary-background-color);
   border-radius: var(--primary-border-radius);
   
}

.wallets-tittle {
   color: var(--primary-text-color);
      font-size: 16px;
      line-height: 16px;
      padding: 45px;
}
.wallets-form-control {
   margin-left: 0;
   margin: 1rem;
   
}

.wallets-currency {
   color: var(--primary-text-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
}
.wallets-amount {
color: #5E5E72;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 10px;
}