.slots-blocked {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  background-color: #00000063;
}
.slots-blocked__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 24px;
  max-width: 500px;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  border-radius: var(--primary-border-radius);
  box-shadow: var(--primary-shadow);
}
.slots-blocked__box {
  margin-bottom: 20px;
}
.slots-blocked__title {
  text-align: center;
  font-size: 28px !important;
  margin-bottom: 12px !important;
}
.slots-blocked__text {
  text-align: center;

  font-size: 16px !important;
  color: var(--secondary-text-color);
}
.slots-blocked__button {
  max-width: 170px;
}

@media (min-width: 500px) {
  .slots-blocked__container {
    padding: 24px 40px;
    max-width: 500px;
  }
  .slots-blocked__box {
    margin-bottom: 32px;
  }
  .slots-blocked__title {
    margin-bottom: 16px;
  }
}
