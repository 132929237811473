.slot-card {
  --shadow-card: 0px 2px 8px 0px #00000040;
  position: relative;
  background-color: var(--primary-background-color) !important;
  border: var(--primary-border) !important;
  border-radius: var(--primary-border-radius) !important;
  box-shadow: var(--shadow-card) !important;
  grid-column-start: span 2;
  grid-row-start: span 2;
  aspect-ratio: 1 / 1;
  cursor: pointer;
}
.slot-card .MuiCardMedia-root {
  object-fit: cover;
  height: calc(100% - 36px);
}
.slot-card .MuiCardContent-root {
  padding: 5px 8px !important;
}
.slot-card.MuiCard-root {
  background-image: none;
  width: 100%;
  height: 100%;
}
.slot-card__title.MuiTypography-root {
  position: relative;
  line-height: var(--primary-line-height);
  font-weight: 700 !important;
  text-align: center;
  color: var(--primary-text-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  margin: 0;
  z-index: 2;
}
.slot-card .MuiCardActions-root {
  position: absolute;
  inset: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  flex-direction: column;
  backdrop-filter: blur(5px);
  justify-content: center;
}
.slot-card__primary-button {
  border: none !important;
  display: block;
  width: auto;
  margin: 0;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: var(--primary-line-height);
  padding: 6px 24px;
  background-color: var(--primary-color);
  color: var(--primary-text-color) !important;
  border-radius: var(--primary-border-radius);
  box-shadow: var(--shadow-card);
  cursor: pointer;
}
.slot-card .MuiCardActions-root .Button-primary {
  margin: 0;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.slot-card.active .slot-card__blur-container {
  /* backdrop-filter: blur(5px); */
  /* display: block; */
}

@media (min-width: 599.99px) {
  .big {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    width: 100%;
    height: 99.33337%;
  }

  .big .MuiCardMedia-root {
    height: 273px;
  }
}

@media (min-width: 839.99px) {
  .slot-card.MuiCard-root {
    background-image: none;
    /* max-width: 175px; */
    width: 100%;
    height: 100%;
    /* max-height: 175px; */
  }
}
@media (min-width: 939.99px) {
  .big {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    width: 100%;
    height: 99.33337%;
  }
  .wide {
    grid-column: 3 / span 2;
    grid-row: 1 / 2;
    width: 100%;
    height: 98.88887%;
  }
  .wide ~ .wide {
    grid-column: 3 / span 2;
    grid-row: 2 / 3;
  }
  .slot-card .MuiCardMedia-root {
    /* height: 130px; */
  }
  .big .MuiCardMedia-root {
    height: 273px;
  }
}
.hovercard {
  opacity: 0;
  visibility: hidden;
  z-index: -1000;
  pointer-events: none;
  transition: all 0.1s ease;
}
.slot-card:hover .hovercard {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  pointer-events: all;
  transition: all 0.1s ease;
}
