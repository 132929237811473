.appbar {
  background-color: var(--secondary-background-color) !important;
  background-image: none !important;

  /* height: 84px;
   justify-content: center; */
}
.appbar-logo {
  object-fit: cover;
  height: 40px;
  cursor: pointer;
}
.appbar-desc {
  /* justify-content: center; */
}

.appbar-mobile {
  /* height: 65px; */
  /* align-items: center;
   justify-content: space-around; */
  /* background-color: var(--primary-background-color) !important;
   background-image: none !important; */
}
@media (min-width: 640px) {
  .appbar {
    background-color: var(--primary-background-color) !important;
  }
  .appbar-logo {
    height: 50px;
  }
}

.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.menu-icon {
  display: block;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.dot,
.line {
  transition: all 0.3s ease-in-out;
}

.menu-button.active .dot {
  opacity: 0;
}

.firstline,
.middleline,
.lastline {
  width: 20px;
}

.menu-button.active .firstline {
  width: 18px;
}

.menu-button.active .middleline {
  width: 25px;
}

.menu-button.active .lastline {
  width: 17px;
}
