.footer-nav {
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 10%;
}
.footer-nav__list {
  width: 100%;
  background-color: var(--primary-background-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.footer-nav__item {
  list-style: none;
  line-height: 10px;
  cursor: pointer;
}
.footer-nav__item-link {
  color: var(--primary-text-color) !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}

@media (min-width: 768.02px) {
  .footer-nav {
    /* margin: 34px 0 24px 0; */
  }

  .footer-nav__item {
    line-height: 14px;
  }
  .footer-nav__item-link {
    white-space: nowrap;
    font-size: 14px;
    line-height: 14px;
  }
}
