.footer-responsive {
  margin-bottom: 24px;
}
.footer-responsive__list {
  background-color: var(--primary-background-color);
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}
.footer-responsive__item {
  list-style: none;
  opacity: 0.67;
  width: auto;
  height: 16px;
}
.footer-responsive__item-image {
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768.02px) {
  .footer-responsive {
    margin-bottom: 34px;
  }
  .footer-responsive__list {
    gap: 14px;
  }
  .footer-responsive__item {
    height: 24px;
  }
}
