.slots-filter {
  display: flex !important;
  gap: 6px;
  padding: 24px 0 7px 0;
  max-width: 100%;
  margin: 0 auto 7px auto;
  overflow: auto;
}

.slots-filter__search {
  width: 52px;
}
.slots-filter__search fieldset {
  border: none;
}
.slots-filter__search .MuiButtonBase-root {
  padding: 10px;
}
.slots-filter__search.active {
  animation: buttonWidth 0.6s ease-out 0s 1 forwards;
}

.slots-filter::-webkit-scrollbar {
  /* display: none; */
  background: var(--primary-background-color);
}
.slots-filter::-webkit-scrollbar-thumb {
  /* display: none; */
  background: var(--secondary-text-color);
}
.slots-filter > div:first-child {
  transition: "all 0.4s ease-in-out";
}

.slots-filter .MuiFormControl-root {
  flex: 0 0 auto;
  /* width: 137px; */
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-background-color);
  overflow: hidden;
}
/* clear button */
.slots-filter__clear .MuiButtonBase-root {
  background-color: var(--secondary-background-color);
  border-color: var(--primary-color) !important;
  padding: 8px 16px !important;
}
/* select */
.slots-filter__select .MuiSelect-icon path {
  display: none;
}
.slots-filter__select .MuiSelect-icon {
  background: url("../../../assets/icons/arrow-down.svg") no-repeat;
  background-size: 14px;
  background-position: center;
  transition: all 0.2s ease-in-out;
}
.slots-filter__select .MuiFormLabel-root {
  color: var(--primary-text-color);
  font-weight: 700;
  line-height: var(--primary-line-height);
  font-size: 12px;
}
.slots-filter__select .MuiFormLabel-root.Mui-focused {
  transform: translate(14px, 9px) scale(1) !important;
}
.slots-filter__select .MuiFormLabel-root.MuiFormLabel-filled {
  opacity: 0;
}
.slots-filter__select.MuiFormControl-root span {
  display: none !important;
}

/* button */
.slots-filter .MuiButtonBase-root {
  color: var(--primary-text-color);
  font-weight: 700;
  line-height: var(--primary-line-height);
  font-size: 12px;
  background-color: var(--primary-background-color);
  border: var(--primary-border);
  padding: 6px 14px;
  flex: 0 0 auto;
}

.slots-filter.MuiButtonBase-root:hover {
  background-color: var(--primary-background-color);
}
.slots-filter__button.active {
  background-color: var(--primary-color) !important;
}

@keyframes buttonWidth {
  from {
    width: 52px;
  }
  to {
    width: 230px;
  }
}
