.footer {
  background-color: var(--primary-background-color);
  border-top: var(--primary-border);
  overflow: hidden;
}

@media (min-width: 768.02px) {
  .footer__flex-container {
    display: flex;
    justify-content: space-around;
  }
}
