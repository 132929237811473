.not_found_container {
  display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 65vh;
    overflow: hidden;
}
.not_found_header {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 100px;
  letter-spacing: 1rem !important;
  
}

.not_found_header img {
  object-fit: cover;
    width: 50px;
  
}
.not_found_subtitle {
  text-transform: uppercase;
  margin: 16px 0;
  letter-spacing: 1rem !important;
  padding: 25px 0 40px 0 !important;
}
.not_found_text {

}


@media screen and (max-width: 800px) {
  .not_found_container {
      min-height: 60vh;
    }
  .not_found_header {
    font-size: 3rem !important;
  }

  .not_found_subtitle {
    font-size: 2rem !important;
    letter-spacing: .5rem !important;
    text-align: -webkit-center;
  }
    .not_found_text {
      text-align: -webkit-center;
    }
}
@media screen and (max-width: 459px) {
  .not_found_container {
      min-height: 35vh;
    }
 
}