.bonus-card__container {
  aspect-ratio: 1/0.85;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: transparent;
  border-radius: var(--primary-border-radius);
  overflow: hidden;
  z-index: 1;
}
.bonus-card__image {
  width: 100%;
  height: 100%;
  position: relative;
}
.bonus-card__bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bonus-card__sub-image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}
.bonus-card__content {
  width: calc(100% - 48px);
  background-color: var(--primary-background-color);
  padding: 10px 24px 0;
}
.bonus-card__content-title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: var(--primary-text-color);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 12px;
}
.bonus-card__content-sub-title {
  font-size: 12px;
  line-height: 14px;
  height: 23px;
  font-weight: 400;
  color: var(--primary-text-color);
  text-align: center;
  letter-spacing: 1.13px;
  margin-bottom: 12px;
  height: 23px;
}
.bonus-card__content-buttons {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-around;
  margin-bottom: 18px;
}
.bonus-card__content-button-rule {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-wrap: nowrap;
  text-decoration-line: underline;
  color: var(--primary-text-color) !important;
}
.bonus-card__content-button-activate {
  outline: none;
  border: 0;
  color: var(--primary-text-color);
  background-color: var(--primary-color);
  border-radius: var(--primary-border-radius);
  padding: 10px 15px;
}
/* ---------------------------------------------------- */
