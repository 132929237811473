.rules {
   background-color: var(--secondary-background-color);
   display: flex !important;
   position: relative;
   min-height: 100vh;
   /* max-width: 1000px; */
   margin: 0 auto;
   color: var(--primary-text-color);
}  

.rules_navigation {

   margin-top: 15px;
   position: sticky;
   top: 0px;
   flex: none;
   width: 350px;
   height: calc(100vh);


   display: flex;
   flex-direction: column;
   overflow: hidden;
   min-width: 280px;
}


.rules_navigation_menu {
   border-radius: var(--primary-border-radius);
   background-color: var(--primary-background-color);
   padding: 1rem 0;
   margin-top: 1rem;
      min-height: calc(100vh - 4rem);
}


.rules-body {
   display: block;
   position: relative;
   margin-top: 18px;
   margin-left: 15px;
}

.rules-body .MuiTypography-root {
   font-size: 12px;
}


.rules_navigation_support {
   display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

   background-color: var(--primary-background-color);
   /* width: 100%; */
      min-height: 90px;
      flex-shrink: 0;
      border-radius: var(--primary-border-radius);
      background-size: cover;
      padding: 15px;
      overflow: hidden;
      position: relative;

      cursor: pointer;
      transition: box-shadow .3s;
}

.rules_navigation_title {
 
   font-weight: 700;
   font-size: 16px;
   color: var(--primary-text-color)

}
.rules_navigation_title a {
   text-decoration: none !important;
   font-weight: 700 !important;
   font-size: 16px !important;
   color: var(--primary-text-color) !important

}

.rules_navigation_subtitle {
   width: 80%;
   font-weight: 500;
   font-size: 12px;
   color: var(--secondary-text-color)
}
.rules_navigation_subtitle a {
   text-decoration: none !important;
   width: 50% !important;
   font-weight: 500 !important;
   font-size: 12px !important;
   color: var(--secondary-text-color) !important
}


.MuiTabs-root.MuiTabs-vertical {
   border: 0 !important;
}


.MuiButtonBase-root.MuiTab-root.Mui-selected {
   border-radius: 0;
   background-color: var(--secondary-background-color);
   color: var(--primary-text-color) !important;
}

.MuiButtonBase-root.MuiTab-root {
   border: 0;
   border-radius: 0 !important;
   color: var(--primary-text-color) !important;
}



.rules_navigation_img {
   position: absolute;
  width: 90px !important;
  height: 100%;
  right: 15px;
transform: scaleX(-1);
}

.accordion {
   margin: 0rem 0 !important;
   color: var(--primary-text-color) !important;
   padding: .5rem;
   box-shadow: none !important;
   background-image: none !important;
   background-color: var(--secondary-background-color) !important;
}
.accordion_summary {
   background-color: var(--primary-background-color) !important;
   border-radius: var(--primary-border-radius) !important;
}

.accordion_summary .MuiTypography-root{
   font-size: 14px;
}
.accordion_details {
   
}

.accordion_details .MuiTypography-root {
   font-size: 12px;
}



.accordion::before {
   display: none;
}
