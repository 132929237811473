h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
button {
  font-family: "Montserrat", sans-serif !important;
  /* letter-spacing: 0.5px !important; */
  font-weight: 600 !important;
  margin: 0;
}
span,
div {
  font-family: "Montserrat", sans-serif !important;
  letter-spacing: 0.5px !important;
}
* {
  border-color: rgba(54, 54, 73, 1) !important;
  -webkit-tap-highlight-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primary-text-color) !important;
  -webkit-box-shadow: 0 0 0px 1000px #131a2f inset !important;
  border-radius: 15px !important;
  transition: background-color 5000s ease-in-out 0s;
}
button,
fieldset {
  border-radius: 14px !important;
}
a {
  color: var(--primary-color) !important;
}
body {
  font-family: "Montserrat", sans-serif !important;

  /* rgba(19, 26, 47, 1) */
  --primary-background-color: rgba(19, 26, 47, 1);
  --secondary-background-color: rgba(27, 39, 64, 1);

  --primary-color: rgb(223, 0, 67);
  --primary-color-hover: rgb(180, 0, 54);
  --secondary-color: rgba(223, 0, 67, 1);

  /* --primary-text-color: #001eff; */
  --primary-text-color: #f8f8f8;
  /* --secondary-text-color: #04ff00; */
  --secondary-text-color: #5e5e72;

  --primary-border: none !important;
  --primary-border-radius: 14px;
  --primary-line-height: 24px;
  --primary-shadow: 0px 2px 8px 0px #00000040;

  background-color: var(--primary-background-color);
  margin: 0;
}

.primary-text-color {
  color: var(--primary-text-color) !important;
}
.secondary-text-color {
  color: var(--secondary-text-color) !important;
}

.css-px39cz-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--primary-text-color) !important;
}
.css-1ky6jtx-MuiTypography-root {
  color: var(--primary-text-color) !important;
}

.css-1jgdtkb-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
}
.css-14t01uy-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
}

input {
  background-color: var(--primary-background-color) !important;
  border-radius: var(--primary-border-radius);
  color: var(--primary-text-color) !important;
}

.css-myb2s4-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill,
.css-14jik6a-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  box-shadow: 0 0 0 100px var(--primary-background-color) inset !important;
  border-radius: 14px !important;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  background-color: var(--secondary-background-color);
}

button:disabled {
  background-color: var(--primary-background-color) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* SCROLL BAR */

::-webkit-scrollbar {
  right: -10px;
  width: 3px;
  height: 5px;
  border-radius: 10px;
  flex-shrink: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5e5e72;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.yaPreloadingSuggestBlockContainer {
display: none !important;
}

#buttonContainerIdyandex .yaPreloadingSuggestBlockContainer {
display: block !important;
}

.secondary-hover:hover {
  background-color: var(--primary-color) !important;
}

/* RED */
.primary-hover:hover {
  background-color: var(--primary-color-hover) !important;
}

.pulse-button {
  animation: pulse 1s infinite;
}
/* .pulse-button:hover {
  background-color: var(--primary-color-hover) !important;
} */

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--primary-color);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--primary-color);
    box-shadow: 0 0 0 0 var(--primary-color);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.Mui-focused fieldset {
  border-width: 1px !important;
}

.container {
  padding: 0 50px;
}

@media (max-width: 1200px) {
  .container {
    padding: 0 30px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 10px;
  }
}
