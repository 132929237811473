.profile-content {
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
}
.profile-content__container {
  width: calc(100% - 28px);
  padding: 0px 14px;
}
/* .profile-layout__big {
  display: none;
} */
.profile-layout__big-container {
  display: none;
  gap: 52px;
}
/* .profile-layout__small {
  display: block;
} */
@media (min-width: 700.98px) {
  .profile-content__container {
    width: 100%;
    padding: 0;
  }
  .profile-layout__big-container {
    display: flex !important;
    gap: 24px;
  }
}
@media (min-width: 1023.98px) {
  .profile-layout__big-container {
    gap: 52px;
  }
}
