.no-active-bonuses__container {
  width: 100%;
}
.no-active-bonuses__promocode {
  width: calc(100% - 12px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px;
  position: relative;
  border-radius: var(--primary-border-radius);
  overflow: hidden;
}
.no-active-bonuses__promocode-image {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  top: 0;
  left: 0;
  opacity: 0.7;
}
.no-active-bonuses__promocode-title {
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  text-transform: uppercase !important;
  color: var(--primary-text-color) !important;
  margin-bottom: 4px !important;
  position: relative;
  z-index: 1;
}
.no-active-bonuses__promocode-input {
  display: block;
  outline: 0;
  border: 0;
  border-radius: var(--primary-border-radius) !important;
  width: 60%;
  line-height: 24px;
  font-size: 10px;
  padding: 3px 10px;
  color: var(--primary-text-color);
  position: relative;
  z-index: 1;
}
@media (min-width: 1024px) {
  .no-active-bonuses__promocode {
    width: 50%;
    padding: 6px 12px;
  }
}
