#payment__modal .MuiDialog-container {
  backdrop-filter: blur(5px);
  position: relative;
}
#payment__modal .MuiPaper-root {
  background-color: var(--secondary-background-color);
  width: 100%;
  max-width: 400px;
  background-image: none;
  padding: 14px 26px 28px;
  border-radius: var(--primary-border-radius);
  position: relative;
  margin: 14px;
}
#payment__modal .MuiDialogTitle-root {
  padding: 0;
  margin-bottom: 8px;
}
#payment__modal .MuiDialogContent-root {
  padding: 0;
}
#payment__modal .MuiDialogContent-root .MuiTypography-root {
  margin-bottom: 14px;
  font-weight: 400 !important;
}
#payment__modal .MuiDialogContent-root img {
  display: block;
  width: 30%;
  object-fit: cover;
  margin: 0 auto;
  margin-bottom: 20px;
}

.payment-modal__form {
  width: calc(100%);
  display: block;
}
.payment-modal__form .payment-modal__amount .MuiInputBase-root {
  border: var(--primary-border);
  border-radius: var(--primary-border-radius);
  margin-bottom: 12px;
}
.payment-modal__form .MuiInputBase-root::after,
.payment-modal__form .MuiInputBase-root::before {
  display: none;
}
.payment-modal__form .MuiInputBase-input {
  padding: 6px 16px;
  color: rgba(255, 255, 255, 0.7);
}
.payment-modal__close {
  cursor: pointer;
  position: absolute;
  /* top: 20px;
  right: 14px; */
  top: 0;
  right: 0;
  border: none;
  background-color: var(--primary-background-color);
  border-radius: 4px !important;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-modal__select {
  margin-bottom: 8px;
}
.payment-modal__bank {
  background-color: var(--primary-background-color);
}
.payment-modal__submit {
  padding: 6px 40px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  text-transform: uppercase;
  border: 0;
  font-size: 16px;
  line-height: var(--primary-line-height);
  box-shadow: var(--primary-shadow);
  cursor: pointer;
}
.payment-modal__button-container {
  display: flex;
  border-radius: var(--primary-border-radius);
  overflow: hidden;
  margin-bottom: 24px;
}
.payment-modal__button {
  flex: 1 1 auto;
  background-color: var(--primary-background-color);
  border-radius: 0 !important;
  border: none;
  color: var(--secondary-text-color);
  font-size: 14px;
  line-height: 18px;
  padding: 8px 0;
  cursor: pointer;
}

#credentials {
  padding: 10px 14px;
  color: rgba(255, 255, 255, 0.7);
}

#payment__modal .MuiInputBase-root.Mui-error {
  border-color: var(--primary-color) !important;
}
@media (min-width: 768px) {
  #payment__modal .MuiPaper-root {
    padding: 28px 38px 46px;
  }
  .payment-modal__close {
    /* top: 32px;
    right: 32px; */
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .payment-modal__form {
    width: calc(100% - 56px);
    display: block;
    padding: 0 28px;
  }
}
