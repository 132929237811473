.game-header {
  height: 56px;
  background-color: var(--primary-background-color);
}

.game-header__container {
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.back {
  border: none;
  padding: 10px;
  border-radius: 0px !important;
  background: transparent;
}
.back::before {
  content: "";
  background-image: url("../../../assets/icons/arrow-down.svg");
  background-color: var(--secondary-background-color);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 101;
  transform: rotate(90deg);
  border: var(--primary-border);
  display: block;
  width: 20px;
  height: 20px;
  border: none;
}
.deposit {
  padding: 6px 40px;
  line-height: var(--primary-line-height);
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: #f8f8f8;
  box-shadow: var(--primary-shadow);
  border: none;
}
@media (min-width: 599.98px) {
  .game-header {
    height: 65px;
  }
}
