.profile-paymenthistory {
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color);
  max-height: 700px;
  max-width: 800px;
}
.profile-paymenthistory__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* -----------------header----------------- */
.profile-paymenthistory__header {
  width: 100%;
  display: flex;
}
.profile-paymenthistory__link {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--primary-text-color) !important;
}
.profile-paymenthistory__link {
  position: relative;
  padding: 14px 0;
  cursor: pointer;
}
.profile-paymenthistory__link span {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0);
  left: 50%;
  width: 0;
  border-radius: 4px;
  display: block;
  height: 2px;
  background-color: var(--primary-color);
}
.profile-paymenthistory__link.active span {
  width: 100%;
  transition: width 0.3s ease-out;
}
/* -----------------header----------------- */
/* -----------------main----------------- */
.profile-paymenthistory__main {
  max-height: calc(700px - 150px - 42px);
  overflow-y: auto;
}
.profile-paymenthistory__payment {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--secondary-text-color);
}
.profile-paymenthistory__payment_date {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 24px;
  color: var(--secondary-text-color);
}
.profile-paymenthistory__payment_amount {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 14px;
  color: var(--primary-text-color);
}
.profile-paymenthistory__payment_card {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: var(--secondary-text-color);
  margin-bottom: 10px;
}
.profile-paymenthistory__payment_status {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  padding: 6px 0;
  line-height: 14px;
  border-radius: var(--primary-border-radius);
}
.profile-paymenthistory__payment_comment {
  height: 0;
  overflow: hidden;
  opacity: 0;
  color: rgba(255, 255, 255, 0.7);
}

.profile-paymenthistory__payment_status.pending {
  color: #ff9100e0;
}
.profile-paymenthistory__payment_status.approved {
  color: #ff9100e0;
}
.profile-paymenthistory__payment_status.success {
  color: #30ab58e0;
}
.profile-paymenthistory__payment_status.rejected {
  color: #e42626e0;
}
.profile-paymenthistory__payment_box.rejected {
  position: relative;
  padding-left: 10px;
  color: #e42626e0;
  border: 1px solid #e42626e0 !important;
  border-radius: var(--primary-border-radius);
  cursor: pointer;
}
.profile-paymenthistory__payment_arrow {
  position: absolute;
  top: 7px;
  right: 10px;
  transition: all 0.3s ease-out;
}
.open .profile-paymenthistory__payment_comment {
  height: 100%;
  padding: 0 6px 10px 0;
  opacity: 1;
  transition: height 0.3s ease-out, opacity 0.3s ease-out 0.2s;
}
.open .profile-paymenthistory__payment_arrow {
  transform: rotate(180deg);
}
/* -----------------main----------------- */
/* -----------------footer----------------- */
.profile-paymenthistory__footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 24px 0 24px 0;
}
.profile-paymenthistory__footer-button {
  padding: 6px 26px;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-text-color);
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  line-height: 24px;
  box-shadow: 0px 2px 8px 0px #00000040;
  cursor: pointer;
}
.profile-paymenthistory__footer-button:disabled {
  color: rgba(255, 255, 255, 0.5);
}
.payment__link {
  color: var(--primary-text-color) !important;
  text-decoration: none;
}
.payment__link:hover {
  /* color: var(--primary-text-color) !important; */
  text-decoration: none;
}
