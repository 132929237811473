.payments {
  /* padding: 24px 0 0 0;
  margin-bottom: 8px; */
}
.payments__image {
  height: inherit;
}
.payments__heading {
  padding: 24px 0 0 0;
  font-size: 12px;
  line-height: 10px;
  color: var(--primary-text-color);
}
.payments__image-container {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 54px;
}
@media (min-width: 768.02px) {
  .payments__heading {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .payments__image-container {
    gap: 16px;
    height: 54px;
  }
  .payments__image {
    height: 100%;
    object-fit: cover;
  }
}
