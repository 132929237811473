.Button {
  color: var(--primary-text-color) !important;
}

.Button-primary {
  border: var(--primary-border);
  background-color: var(--secondary-background-color) !important;
}

.Button-secondary {
  background-color: var(--primary-color) !important;
}
