.payment-card {
  position: relative;
  width: 100%;
  background-color: var(--primary-background-color) !important;
  background-image: none !important;
  border-radius: var(--primary-border-radius) !important;
  border: var(--primary-border);
}
.payment-card__image {
  object-fit: contain !important;
  transform: scale(0.8);
}
.payment-card__active-container {
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.payment-card__action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.payment-card__action-button {
  padding: 4px 10px;
  border: 0;
  color: var(--primary-text-color);
  background-color: var(--primary-color);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  border-radius: 14px;
  cursor: pointer;
  box-shadow: var(--primary-shadow);
}
.payment-card__label {
  width: fit-content;
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  font-weight: 600;
  transform: translate(-50%, -50%);
  color: var(--primary-text-color);
}

.payment-card__recommended-badge{
  height: 30px !important;
  width: 30px !important;
  position: absolute;
    top: 0%;
    right: 0%;
}