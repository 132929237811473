.profile-personal-info {
  display: flex;
  background-color: var(--secondary-background-color);
  border-radius: var(--primary-border-radius);
  border: var(--primary-border);
  width: 100%;
}

.profile-personal-info__form {
  padding: 24px 14px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.profile-personal-info__field {
  margin-bottom: 18px;
}

.profile-personal-info__label {
  display: block !important;
  color: var(--primary-text-color) !important;
  font-size: 14px !important;
  line-height: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 8.3px !important;
}
.profile-personal-info__birth {
  display: flex;
  gap: 10px;
}
.profile-personal-info__birth .date {
  flex: 1 1 auto;
}
.profile-personal-info__birth .month {
  flex: 2 1 auto;
}
.profile-personal-info__birth .year {
  flex: 1 1 auto;
}

@media (min-width: 768px) {
  .profile-personal-info__form {
    padding: 48px;
  }
  .profile-personal-info__field {
    margin-bottom: 28px;
  }
}
