.password-container {
  /* background-color: var(--secondary-background-color); */
  margin: 0;
  padding: 0 !important;
}
.container-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-background-color);
  border-radius: var(--primary-border-radius);
  padding: 0 0;
  /* min-width: 330px; */
}

.password-form {
  padding: 0 2rem;
  width: calc(100% - 30px);
  text-align: center;
}

@media (min-width: 600px) {
  .password-container {
    /* margin: 2rem auto; */
  }
  .password-form {
    max-width: 300px;
    min-width: 50%;
  }
}
