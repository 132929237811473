.profile-verification {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--secondary-background-color);
  border-radius: var(--primary-border-radius);
  border: var(--primary-border);
}
.profile-verification__container {
  padding: 19px 14px 29px 14px;
}
.profile-verification__form {
  width: 100%;
  margin-bottom: 18px;
  max-width: 500px;
  margin: 0 auto;
}
.profile-verification__label {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.4000000059604645px !important;
  color: var(--primary-text-color);
  margin-bottom: 14px !important;
}
.profile-verification__label.label {
  display: block;
  font-weight: 700 !important;
  margin-bottom: 8px !important;
  font-size: 14px !important;
}
.profile-verification__label.requirements {
  display: block;
  font-weight: 400 !important;
  margin: 0 0 4px 10px !important;
  font-size: 12px !important;
}
.profile-verification__input {
  display: inherit;
  margin: 0;
  padding: 0;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}
.profile-verification__info-container {
  display: flex;
  gap: 10px;
  align-items: stretch;
  width: 100%;
  margin-bottom: 24px;
}
.profile-verification__info {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  justify-content: flex-start !important;
  gap: 8px;
  background-color: var(--primary-background-color) !important;
  border-radius: var(--primary-border-radius) !important;
  border: var(--primary-border);
  background-image: none !important;
  padding: 8px 0px 12px 0px;
  box-shadow: var(--primary-shadow) !important;
  cursor: pointer;
}
.profile-verification__info img {
  width: 40px;
}
.profile-verification__info .MuiCardContent-root {
  padding: 0;
  text-align: center;
  font-size: 11px;
  line-height: 12px;
  color: var(--primary-text-color);
}
.profile-verification__info .MuiCardContent-root:last-child {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .profile-verification__container {
    padding: 34px 48px 48px 48px;
  }
  .profile-verification__form {
    margin-bottom: 36px;
  }
  .profile-verification__label.label {
    margin-bottom: 10px !important;
  }
  .profile-verification__button-container {
    margin-bottom: 26px;
  }
  .profile-verification__info-container {
    gap: 24px;
  }
  .profile-verification__info {
    padding: 8px 4px 12px 4px;
  }
}
