.profile-contacts {
  display: flex;
  width: 100%;
  background-color: var(--secondary-background-color);
  border-radius: var(--primary-border-radius);
  border: var(--primary-border);
}
.profile-contacts__form {
  width: 100%;
  padding: 28px 14px;
  max-width: 500px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .profile-contacts__form {
    padding: 48px;
  }
}
