.available-bonuses {
  display: flex;
  width: 100%;
  /* max-width: 500px; */
  background-color: var(--secondary-background-color);
  border-radius: var(--primary-border-radius);
  border: var(--primary-border);
}

.available-bonuses__container {
  min-height: 550px;
  position: relative;
  padding: 19px 14px 29px 14px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  justify-content: start;
  flex-wrap: wrap;
}
.available-bonuses__bonuses-list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  /* column-gap: 1rem; */
  gap: 1rem;
  grid-auto-rows: 250px;
}
.available-bonuses__item {
  flex: 0 1 calc(100%);
  max-width: calc(100%);
}

@media (max-width: 800px) {
  .available-bonuses__bonuses-list {
    grid-template-columns: 1fr;
    grid-auto-rows: 172px;
  }
}
@media (min-width: 800px) {
  .available-bonuses__container {
    padding: 24px;
    min-height: calc(70vh - 24px);
  }
}
@media (min-width: 1300px) {
  .available-bonuses__item {
    flex: 0 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}
