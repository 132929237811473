.slot-category {
  margin-bottom: 18px;
  background-color: inherit;
}
.slot-lobby {
  /* border: 5px solid #aaaa; */
}
.slot-category__header {
  display: flex;
  justify-content: space-between;
}
.slot-category__heading {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-text-color);
  text-transform: uppercase;
  line-height: 14px;
  margin-bottom: 14px;
}
.slots-categories__button-container {
  display: flex;
  justify-content: center;
  gap: 2px;
}
.slots-categories__button {
  width: auto !important;
  margin: 14px 0 0 0 !important;
  font-size: 12px !important;
  line-height: var(--primary-line-height) !important;
  padding: 6px 26px !important;
}
.slots-categories__dots {
  border-radius: 14px;
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
}
.dot__1 {
  width: 32px;
  animation: dot_1 3s infinite linear;
}
.dot__2 {
  width: 8px;
  animation: dot_2 3s infinite linear;
}
.dot__3 {
  width: 8px;
  animation: dot_3 3s infinite linear;
}
@media (min-width: 599.98px) {
  .slot-category {
    margin-bottom: 34px;
  }
  .slot-category__heading {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 18px;
  }
  /* .slot-category .MuiGrid-root {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-template-rows: minmax(150px, 1fr);
  } */
}

.slot-category .MuiGrid-root {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); */
  /* grid-template-rows: minmax(140px, 1fr); */
  /* column-gap: 19px;
  row-gap: 14px; */
  /* justify-items: center; */
  grid-template-columns: repeat(4, 1fr);
  column-gap: 19px;
  row-gap: 14px;
}

@media (min-width: 500px) {
  .slot-category .MuiGrid-root {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 768px) {
  .slot-category .MuiGrid-root {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 1100px) {
  .slot-category .MuiGrid-root {
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1800px) {
  .slot-category .MuiGrid-root {
    grid-template-columns: repeat(16, 1fr);
  }
}
@keyframes dot_1 {
  0% {
    width: 32px;
  }
  25% {
    width: 8px;
  }
  75% {
    width: 8px;
  }
  100% {
    width: 32px;
  }
}
@keyframes dot_2 {
  0% {
    width: 8px;
  }
  25% {
    width: 32px;
  }
  50% {
    width: 8px;
  }
  75% {
    width: 32px;
  }
  100% {
    width: 8px;
  }
}
@keyframes dot_3 {
  0% {
    width: 8px;
  }
  25% {
    width: 8px;
  }
  50% {
    width: 32px;
  }
  75% {
    width: 8px;
  }
  100% {
    width: 8px;
  }
}
