.provider {
  color: var(--primary-text-color) !important;
  background-color: var(--secondary-background-color) !important;
  padding-bottom: 26px !important;
}
.provider__heading {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: var(--primary-text-color) !important;
  text-transform: uppercase !important;
  line-height: 14px !important;
  margin-bottom: 14px !important;
}

.provider-card {
  width: 148px !important;
  height: 90px !important;
  padding: 8px !important;
  margin: 0 12px !important;
  border: var(--primary-border) !important;
  border-radius: var(--primary-border-radius) !important;
  background-color: var(--primary-background-color) !important;
  background-image: none !important;
  box-shadow: var(--primary-shadow) !important;
}
.provider-card__image {
  width: 100% !important;
  max-height: 90px !important;
  height: 100% !important;
  object-fit: contain !important;
}
.provider .slick-arrow {
  width: 30px;
  height: 30px;
  border: var(--primary-border);
  background-color: var(--primary-background-color);
  background-image: none;
  border-radius: 0;
  box-shadow: var(--primary-shadow);
}
.provider .slick-arrow::before {
  position: absolute;
  top: 0;
  font-size: 0;
  opacity: 1;
  background-image: url("../../../assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
}
.provider .slick-prev {
  left: -37px;
}
.provider .slick-prev::before {
  left: 0;
  transform: rotate(90deg);
}
.provider .slick-next {
  right: -37px;
}
.provider .slick-next::before {
  left: 0;
  transform: rotate(-90deg);
}
@media (min-width: 599.98px) {
  .provider {
    padding-bottom: 34px !important;
  }
  .provider__heading {
    font-size: 16px !important;
    line-height: 16px !important;
    margin-bottom: 18px !important;
  }
}
