.active-bonuses {
  display: flex;
  width: 100%;
}

.active-bonuses__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  justify-content: flex-start;
  min-height: 550px;
  position: relative;
}

.left-panel {
  flex: 0 0 400px;
  background-color: var(--left-panel-background);
  height: 100%;
  border-radius: var(--primary-border-radius);
}

.active-bonuses__bonuses-list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 1rem;
}

.active-bonuses__item {
  flex: 0 1 100%;
  max-width: 500px;
}

.no-bonuses {
  min-height: 550px;
  width: 100%;
}

@media (min-width: 800px) and (max-width: 1679.98px) {
  .active-bonuses__bonuses-list {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1023.98px) {
  .active-bonuses__container {
    padding: 15px;
    background-color: var(--secondary-background-color);
    border-radius: var(--primary-border-radius);
    min-height: calc(70vh - 24px);
  }
}
@media (min-width: 1679.98px) {
  .active-bonuses__bonuses-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
