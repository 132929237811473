.game {
  background-color: var(--secondary-background-color);
  position: relative;
}
.game-dialog .MuiBackdrop-root {
  backdrop-filter: blur(10px);
  background-color: transparent !important;
}
.game-dialog .MuiPaper-root {
  width: 100%;
  max-width: 350px;
  padding: 24px 24px 48px 24px;
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color);
  background-image: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.game-dialog .MuiPaper-root h2 {
  text-align: center;
  padding: 0;
  color: #f8f8f8;
  margin-bottom: 36px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: var(--primary-line-height);
}
.game-dialog .MuiPaper-root .MuiDialogContent-root {
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}
.game-dialog .MuiPaper-root .MuiDialogContent-root .MuiTypography-root {
  text-align: center;
  padding: 0;
  color: #f8f8f8;
  font-size: 12px;
  line-height: 18px;
  max-width: 250px;
  margin-bottom: 24px;
}
.game-dialog .MuiPaper-root .MuiDialogActions-root {
  padding: 0;
  display: flex;
  column-gap: 34px;
}
.game-dialog .MuiPaper-root .MuiDialogActions-root button {
  margin: 0;
  width: auto;
  text-transform: capitalize;
  line-height: var(--primary-line-height);
  border: none !important;
  box-shadow: var(--primary-shadow);
}
/* .game-dialog .MuiPaper-root .MuiDialogActions-root button:first-child {
  background-color: var(--primary-color) !important;
}
.game-dialog .MuiPaper-root .MuiDialogActions-root button:last-child {
  background-color: var(--primary-background-color) !important;
} */

.game-dialog-action {
  background-color: var(--primary-background-color) !important;
}
