.profile-select {
  position: relative;
}
.profile-select .MuiFormControl-root {
  margin: 0;
}
.profile-select .MuiInputBase-root {
  border: none !important;
}
.profile-select .MuiSelect-select {
  padding: 10px 14px;
  line-height: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: var(--primary-background-color);
  border-radius: var(--primary-border-radius) !important;
  border: none !important;
}
.profile-select .MuiSelect-select.Mui-disabled {
  background: rgba(255, 255, 255, 0.1) !important;
}
.profile-select .MuiSelect-icon path {
  display: none;
}

.profile-select .MuiSelect-icon {
  background: url("../../assets/icons/arrow-down.svg") no-repeat;
  background-size: 14px;
  background-position: center;
  transition: all 0.2s ease-in-out;
}
.profile-select .Typography-error {
  bottom: -18px;
}

.profile-select .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
}
