.profile-submit-button__container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.profile-submit-button {
  padding: 6px 26px;
  color: var(--primary-text-color);
  background-color: var(--primary-color);
  border-radius: var(--primary-border-radius);
  border: none;
  box-shadow: var(--primary-shadow);
  line-height: var(--primary-line-height);
  text-transform: uppercase;
  height: 36px;
  width: 150px;
}
.profile-submit-button:disabled {
  background: rgba(255, 255, 255, 0.3) !important;
}
