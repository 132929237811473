.provider-select {
  position: relative;
}
.provider-select__button {
  height: 40px;
  position: relative;
  padding: 6px 48px 6px 24px;
  text-transform: uppercase;
  border: var(--primary-border);
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  line-height: 24px;
  font-size: 12px;
  font-weight: 700;
}
.provider-select__button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 48px;
  font-size: 0;
  opacity: 1;
  background-image: url("../../../assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
}
.provider-select__portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;

  z-index: 2;
  background-color: #131a2fb5;
}
.provider-select__container {
  position: absolute;
  display: flex;
  top: 112px;
  left: 0px;
  width: 100%;
  height: calc(100% - 150px);
  background-color: var(--secondary-background-color);
  padding: 24px 0px 14px;
  border-radius: none;
  border: none;
  overflow: hidden;
  opacity: 0;
  animation: opa 0.2s ease 0s 1 forwards;
  display: flex;
  flex-direction: column;
}
.provider-select__title__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.provider-select__title {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  color: var(--primary-text-color);
  padding: 0 20px;
}
.provider-select__title__icon {
  padding: 4px 4px;
  margin-right: 20px;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  border-radius: 8px;
  border: var(--primary-border);
}
.provider-select__input-container {
  padding: 0 20px;
}
.MuiPaper-root.provider-select__input-form {
  box-shadow: none;
  padding: 9px 40px 9px 36px;
  display: flex;
  align-items: center;
  border: var(--primary-border);
  background-color: var(--primary-background-color);
  background-image: none;
  border-radius: var(--primary-border-radius);
  position: relative;
  margin-bottom: 10px;
}
.MuiInputBase-input.provider-select__input {
  background-color: var(--primary-background-color) !important;
  padding: 0 0 0 14px;
  font-size: 12px;
  line-height: 12px;
}
.provider-select__list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.provider-select__list-container {
  display: flex;
  gap: 24px;
  padding: 18px 24px;
  position: relative;
  align-items: stretch;
}
.provider-select__list-container::after {
  content: "";
  display: block;
  width: calc(100% + 48px);
  position: absolute;
  bottom: 0;
  left: -24px;
  height: 0.5px;
  background-color: #363649;
}
.provider-select__list-container:last-child::after {
  content: "";
  display: block;
  width: calc(100% + 48px);
  position: absolute;
  bottom: 0;
  left: -24px;
  height: 0.5px;
  background-color: transparent;
}
.provider-select__list-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  width: 26px;
  color: var(--primary-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.provider-select__list-provider-name {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: var(--primary-text-color);
  margin-bottom: 10px;
  cursor: pointer;
}
.provider-select__list-provider-name:last-child {
  margin-bottom: 0px;
}

@keyframes opa {
  from {
    transform: translate(-100px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
}
@media (min-width: 600px) {
  .provider-select__portal {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 3px);
    z-index: 2;
    background-color: transparent;
  }
  .provider-select__container {
    position: absolute;
    display: block;
    top: 162px;
    left: 24px;
    width: 400px;
    height: auto;
    max-height: 500px;
    background-color: var(--primary-background-color);
    padding: 26px 0px 14px;
    border-radius: var(--primary-border-radius);
    border: var(--primary-border);
    overflow: hidden;
    opacity: 0;
    animation: opa 0.2s ease 0s 1 forwards;
    display: flex;
    flex-direction: column;
    box-shadow: var(--primary-shadow);
  }
  .provider-select__container.main {
    top: 584px;
    left: 12px;
  }
  .provider-select__input-container {
    padding: 0 24px;
  }
  .MuiPaper-root.provider-select__input-form {
    background-color: var(--secondary-background-color);
  }
  .MuiInputBase-input.provider-select__input {
    background-color: var(--secondary-background-color) !important;
    padding: 0 0 0 14px;
    font-size: 12px;
    line-height: 12px;
  }
  .provider-select__list {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .provider-select__list-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    width: 26px;
    color: var(--primary-text-color);
  }

  .provider-select__list-provider-name {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: var(--primary-text-color);
  }
}
@media (min-width: 1200px) {
  .provider-select__container.main {
    top: calc(584px + 26px);
  }
}
