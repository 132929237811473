.footer-all-reserved {
  background-color: var(--secondary-background-color);
  border-top: var(--primary-border);
}
.footer-all-reserved__heading {
  padding: 20px 0;
  font-size: 8px;
  line-height: 10px;
  font-weight: 400 !important;
  color: var(--primary-text-color);
}
@media (min-width: 768.02px) {
  .footer-all-reserved__heading {
    padding: 20px 0;
    font-size: 12px;
    line-height: 12px;
  }
}
