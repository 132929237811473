.my_activated_bonus__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color);
  box-shadow: var(--primary-shadow);
}
.my_activated_bonus__image__container {
  width: 100%;
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-background-color);
  position: relative;
  overflow: hidden;
  min-height: 184px;
}
.my_activated_bonus__info__container {
  width: 100%;
  position: relative;
  margin-top: 16px;
}
.my_activated_bonus__bonus-type {
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 14px;
  z-index: 2;
  gap: 6px;
}
.my_activated_bonus__bonus-type-title {
  color: var(--primary-text-color);
  font-weight: 400 !important;
  margin-bottom: 10px;
}
.my_activated_bonus__image__bg__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.my_activated_bonus__image__sub__image {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.my_activated_bonus__image__content {
  position: relative;
  z-index: 2;
  width: calc(100% - 32px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
}
.my_activated_bonus__image__content__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.my_activated_bonus__image__content__title {
  max-width: 60%;
  font-size: 12px !important;
  color: var(--primary-text-color);
  margin: 12px 0 10px !important;
}
.my_activated_bonus__image__content__sub-title {
  max-width: 60%;
  text-transform: uppercase;
  font-size: 10px !important;
  line-height: 14px !important;
  color: var(--primary-text-color);
  margin-bottom: 18px !important;
}
.my_activated_bonus__image__content__link {
  color: var(--primary-text-color) !important;
  font-size: 10px;
  line-height: 14px;
  text-decoration: underline;
  margin-bottom: 16px;
}
.my_activated_bonus__image__content__button {
  width: 150px;
  outline: 0;
  border: none;
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 16px;
  margin-bottom: 20px;
}
.my_activated_bonus__info__bonus-type,
.my_activated_bonus__info__bonus-status,
.my_activated_bonus__info__bonus-time {
  color: var(--primary-text-color);
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  padding: 0px 15px;
}
.my_activated_bonus__info__bonus-type span,
.my_activated_bonus__info__bonus-status span,
.my_activated_bonus__info__bonus-time span {
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}
.my_activated_bonus__info__bonus-wager {
  width: 100%;
  display: flex;
  margin: 15px 0;
}
.my_activated_bonus__info__bonus-wager svg {
  width: 60px;
  height: 60px;
}
.my_activated_bonus__info__bonus {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 72px 30px;
}
.my_activated_bonus__info__bonus .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.my_activated_bonus__info__bonus-title {
  height: 100%;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-text-color);
}
.my_activated_bonus__info__bonus-description {
  height: 100%;
  color: var(--primary-text-color);
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding: 0px 15px;
}
.my_activated_bonus__info__bonus-description span {
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}
.history .my_activated_bonus__image__bg__image {
  filter: grayscale(0.8);
}
.history .my_activated_bonus__image__sub__image {
  filter: grayscale(0.8);
}
.history .my_activated_bonus__info__bonus-wager svg circle:nth-child(2) {
  stroke: rgb(44, 44, 44);
}
.my_activated_bonus__info__bonus-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 9px;
  top: -6px;
  cursor: pointer;
  padding: 6px;
  color: var(--primary-text-color);
}
.my_activated_bonus__info__bonus-delete:hover {
  background-color: var(--primary-background-color);
  border-radius: 9px;
}
@media (min-width: 700.98px) {
  .my_activated_bonus__container {
    flex-wrap: wrap;
  }
  .my_activated_bonus__info__bonus-wager svg {
    width: 100px;
    height: 100px;
  }
  .my_activated_bonus__info__bonus {
    grid-template-rows: 30px 112px 30px;
  }
}
@media (min-width: 1024px) {
  .my_activated_bonus__container {
    background-color: var(--primary-background-color);
  }
  .my_activated_bonus__info__bonus-wager svg circle:nth-child(1) {
    stroke: var(--secondary-background-color);
  }
}
@media (min-width: 1171.98px) {
  .my_activated_bonus__container {
    flex-wrap: nowrap;
  }
  .my_activated_bonus__image__content__info {
    justify-content: center;
  }
  .my_activated_bonus__image__content__title {
    max-width: 60%;
    font-size: 18px !important;
    margin: 12px 0 14px !important;
  }
  .my_activated_bonus__image__content__sub-title {
    max-width: 60%;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-bottom: 22px !important;
  }
  .my_activated_bonus__image__content__link {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 0px;
  }
  .my_activated_bonus__info__bonus-type,
  .my_activated_bonus__info__bonus-status,
  .my_activated_bonus__info__bonus-time {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 15px;
  }
  .my_activated_bonus__info__bonus-type span,
  .my_activated_bonus__info__bonus-status span,
  .my_activated_bonus__info__bonus-time span {
    font-size: 12px;
    line-height: 16px;
  }
  .my_activated_bonus__info__bonus-title {
    padding: 0 15px;
    font-size: 14px;
  }
  .my_activated_bonus__info__bonus-description {
    font-size: 14px;
  }
  .my_activated_bonus__info__bonus-description span {
    font-size: 12px;
    line-height: 16px;
  }
  .my_activated_bonus__info__bonus {
    grid-template-rows: 30px 112px 48px;
  }
}

@media (min-width: 1679.98px) {
  .my_activated_bonus__container {
    flex-wrap: wrap;
  }
  .my_activated_bonus__image__container {
    min-height: 270px;
  }
}
@media (min-width: 2079.98px) {
  .my_activated_bonus__container {
    flex-wrap: nowrap;
  }
}
