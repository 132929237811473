.profile-header {
  width: calc(100% - 28px);
  overflow: auto;
  scrollbar-width: none;
  padding-left: 14px;
  padding-bottom: 14px;
  padding-right: 28px;
}
.profile-header::-webkit-scrollbar {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}

.profile-header__list {
  display: flex;
  gap: 10px;
  width: 100%;
}
.profile-header__item.MuiButtonBase-root {
  display: block;
  border-radius: var(--primary-border-radius);
  border: var(--primary-border);
  padding: 0;
  overflow: hidden;
  width: max-content;
  flex-grow: 0;
  flex-shrink: 0;
}
.profile-header__item:first-child .profile-header__item-link {
  height: 100%;
  padding: 0 18px;
  content: "";
  background-image: url("../../../assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(90deg);
}
.profile-header__item-link {
  display: block;
  background-color: var(--secondary-background-color);
  color: var(--primary-text-color) !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: var(--primary-line-height);
  font-weight: 700;
  padding: 6px 26px;
  white-space: nowrap;
}
.profile-header__item-link.active {
  color: var(--primary-text-color) !important;
  background-color: var(--primary-color);
}

@media (min-width: 699.95px) {
  .profile-header {
    padding-left: 0px;
  }
}

@media (min-width: 768px) {
  .profile-header__item-link {
    padding: 6px 40px;
  }
}
