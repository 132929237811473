.contacts {
  margin-bottom: 14px;
}
.contacts__heading {
  font-size: 12px;
  line-height: 10px;
  text-transform: capitalize;
  color: var(--primary-text-color);
  margin-bottom: 4px;
}
.contacts__link {
  color: var(--primary-text-color) !important;
  text-decoration: underline;
  font-size: 8px;
  line-height: 10px;
  line-height: var(--primary-line-height);
  display: inline-flex;
  width: auto;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
/* .contacts__link a {
color: var(--primary-text-color) !important;
} */

.contacts__link:visited {
  color: var(--primary-text-color) !important;
}
.contacts__links-container {
  display: inline-flex;
  flex-direction: column;
}
@media (min-width: 768.02px) {
  .contacts {
    width: 100%;
    margin-bottom: 56px;
  }
  .contacts__heading {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px;
  }
  .contacts__link {
    font-size: 14px;
    line-height: 24px;
    gap: 10px;
  }
  .contacts__links-container {
    gap: 8px;
  }
}
