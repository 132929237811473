.slot-filter-category__card {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  max-width: calc(100% - 24px);
  height: 80px;
  background-color: var(--primary-background-color);
  border-radius: var(--primary-border-radius);
  border: var(--primary-border);
  box-shadow: var(--primary-shadow);
  cursor: pointer;
}
.slot-filter-category__card:hover {
  transform: scale(1.03);
  transition: all 0.2s ease;
}
.slot-filter-category__title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: var(--primary-text-color);
  z-index: 1;
}
.slot-filter-category__image {
  width: 40%;
  max-width: 100px;
  object-fit: cover;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  z-index: 0;
}
.slot-category.slot-filter-category .MuiGrid-root {
  grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  grid-template-rows: minmax(120px, 1fr);
  column-gap: 24px;
  row-gap: 24px;
}
.slot-filter-category {
  position: relative;
  max-height: 480px;
  overflow: hidden;
  transition: all 0.3s ease-out;
}
.slot-filter-category.more {
  max-height: 100%;
}
.slot-category__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  background: linear-gradient(#ffffff00, var(--secondary-background-color) 70%);
  z-index: 3;
}
@media (min-width: 600px) {
  .slot-filter-category {
    max-height: 100%;
  }
  .slot-category.slot-filter-category .MuiGrid-root {
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    grid-template-rows: minmax(120px, 1fr);
  }
}
