/* .swiper .swiper-wrapper .swiper-slide:first-child {
  margin-left: -15%;
} */
.swiper {
  padding: 0px 16px 18px;
  --swiper-navigation-size: 24px;
  --swiper-theme-color: var(--primary-text-color);
}
.swiper-button-prev,
.swiper-button-next {
  display: none;
  width: 48px;
  border-radius: var(--primary-border-radius);
  background-color: var(--secondary-background-color);
  margin-top: 0;
  top: 13px;
}
.swiper-button-prev {
  right: 90px;
  left: auto;
}
.swiper-button-next {
  right: 30px;
  left: auto;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: var(--primary-color);
}
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  width: 16px;
  height: 16px;
  font-family: "Times New Roman", Times, serif;
  background: url("../../assets/icons/arrow-down.svg");
  background-repeat: no-repeat;
}
.swiper-button-prev::after {
  transform: rotate(90deg) translate(0, 2px);
}
.swiper-button-next::after {
  transform: rotate(-90deg) translate(0, 2px);
}

.swiper-slide img {
  object-fit: cover;
  position: absolute;
}
.swiper-slide .slider-background-image {
  width: calc(100% + 72px);
  height: 100%;
  left: -36px;
  z-index: 0;
}
.swiper-slide .slider-sub-image {
  z-index: 1;
  height: 100%;
  right: -20px;
}

@media (min-width: 600px) {
  .swiper {
    padding: 50px 30px 24px;
    --swiper-navigation-size: 24px;
    --swiper-theme-color: var(--primary-text-color);
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
  }
  .swiper-button-prev {
    right: 90px;
    left: auto;
  }
  .swiper-button-next {
    right: 30px;
    left: auto;
  }
}

@media (min-width: 1201px) {
  .swiper {
    padding: 50px;
    --swiper-navigation-size: 24px;
    --swiper-theme-color: var(--primary-text-color);
  }

  .swiper-button-prev {
    right: 110px;
    left: auto;
  }
  .swiper-button-next {
    right: 50px;
    left: auto;
  }
}
